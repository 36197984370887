<template>
    <div class="box">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
  name: 'App'
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #333;
}
body {
  height: 100%;
  /* background-color: #f0f0f0; */
  /* background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%); */
  /* background-image: linear-gradient(to right, #ECE9E6 0%, #FFFFFF  51%, #ECE9E6  100%); */
  background-image: linear-gradient(120deg, #f7f7f7 0%, #f7f7f7 100%);

  color: #333333;
}
</style>
