import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router'
import 'vue-area-linkage/dist/index.css'
import VueAreaLinkage from 'vue-area-linkage'
import SIdentify from './components/Identify.vue'
import * as echarts from 'echarts'
import VueLazyload from 'vue-lazyload' // 图片懒加载
import dataV from '@jiaminghi/data-view' // echart DataV
import PuzzleVcode from 'vue-puzzle-vcode' // 导入验证码
import 'font-awesome/css/font-awesome.min.css'
Vue.use(dataV)
Vue.prototype.$echarts = echarts
Vue.component('puzzle-vcode', PuzzleVcode) // 挂载
Vue.component('SIdentify', SIdentify)
Vue.use(VueAreaLinkage)

Vue.use(ElementUI)

Vue.config.productionTip = false

// // 定义懒加载图片或者文件等，自定义指令
// Vue.directive('lazy', (el, binding) => {
//   const oldSrc = el.src // 保存旧的src，方便后期渲染时候赋值src真实路径
//   el.src = '' // 将渲染的src赋为空，则不会渲染图片出来
//   const observer = new IntersectionObserver(([{ isIntersecting }]) => { // 调用方法得到该elDOM元素是否处于可视区域
//     if (isIntersecting) { // 回调是否处于可视区域，true or false
//       el.src = oldSrc // 如果处于可视区域额，将最开始保存的真实路径赋予DOM元素渲染
//       observer.unobserve(el) // 只需要监听一次即可，第二次滑动到可视区域时候不在监听
//     }
//   })
//   observer.observe(el) // 调用方法
// })
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
Vue.use(VueLazyload, {
  loading: require('./assets/nylon/preview.gif'), // 配置加载时显示的图片，可以更换目录
  error: require('@/assets/nylon/preview.gif') // 加载失败时显示的图像
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
