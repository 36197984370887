import Vue from 'vue' // 引入基础文件
import VueRouter from 'vue-router'
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// const routerPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function (location) {
//     return routerPush.call(this, location).catch(err => {
//         console.log(err);
//     })
// }
Vue.use(VueRouter)

const routes = [ // 这里注意,routes，不要写成routers。这部分即路由核心！
  {
    path: '/', // 跳转后路径显示
    redirect: '/index/homepage',
    component: () => import('../pages/begin/index'),
    children: [
      {
        path: '/me',
        name: 'meIndex',
        component: () => import('../pages/me/index'),
        children: [
          {
            path: 'aboutMe',
            name: 'aboutMe',
            component: () => import('../pages/me/aboutMe.vue')
          },
          {
            path: 'hetongMe',
            name: 'hetongMe',
            component: () => import('../pages/me/hetongMe.vue')
          },
          {
            path: 'bussSee',
            name: 'bussSee',
            component: () => import('../pages/me/bussSee.vue')
          },
          {
            path: 'addRess',
            name: 'addRess',
            component: () => import('../pages/me/addRess.vue')
          },
          {
            path: 'goodsDetail',
            name: 'goodsDetail',
            component: () => import('../pages/me/goodsDetail.vue')
          }
        ]
      },
      {
        path: '/index',
        name: 'index', // 设置路由“组件”名称，这里起名不规范，除主页外最好采用驼峰命名法例如：TheHome这种
        component: () => import('../pages/Index/index'), // 您瞅瞅，这import千万不能漏，否则就会不报错不跳转
        children: [
          /* 项目演示 */
          {
            path: 'buyerpage',
            name: 'buyerPage',
            component: () => import('../pages/demo/buyer.vue')
          },
          {
            path: 'sellerpage',
            name: 'sellerPage',
            component: () => import('../pages/demo/seller.vue')
          },
          {
            path: 'presellpage',
            name: 'presellPage',
            component: () => import('../pages/demo/presell.vue')
          },
          {
            path: 'preselldetailpage',
            name: 'presellDetailPage',
            component: () => import('../pages/demo/presellDetail.vue')
          },
          {
            path: 'messagelist',
            name: 'messageListPage',
            component: () => import('../pages/demo/messageList.vue')
          },
          {
            path: 'homepage',
            name: 'HomePage',
            component: () => import('../pages/homePage/index')
          },
          {
            path: 'tradingHall',
            name: 'TradingHall',
            component: () => import('../pages/tradingHall/index')
          },
          {
            path: 'relation',
            name: 'relation',
            component: () => import('../pages/relation/index')
          },
          {
            path: 'commonHall',
            name: 'commonHall',
            component: () => import('../pages/tradingHall/common')
          },
          {
            path: 'buydetail',
            name: 'buyDetail',
            component: () => import('../pages/tradingHall/buydetail.vue')
          },
          {
            path: 'aboutus',
            name: 'aboutUs',
            component: () => import('../pages/aboutUs/index')
          },

          {
            path: 'newsinformation',
            name: 'newsInformation',
            redirect: {
              name: 'list'
            },
            component: () => import('../pages/newsInformation/index'),
            children: [
              {
                path: 'list',
                name: 'list',
                component: () => import('../pages/newsInformation/list')
              },
              {
                path: 'detail',
                name: 'detail',
                component: () => import('../pages/newsInformation/detail')
              }
            ]
          },
          {
            path: 'helpcenter',
            name: 'helpCenter',
            component: () => import('../pages/helpCenter/index'),
            redirect: '/helpregister',
            children: [{
              path: '/helpregister',
              name: 'HelpRegister',
              component: () => import('../pages/helpCenter/detail/Register.vue')
            },
            {
              path: '/helpAuthentication',
              name: 'HelpAuthenTication',
              component: () => import('../pages/helpCenter/detail/Authentication.vue')
            },
            {
              path: '/ServiceAgreement',
              name: 'ServiceAgreement',
              component: () => import('../pages/helpCenter/detail/ServiceAgreement.vue')
            }

            ]

          }
        ]
      },
      // {
      //   path: '/shopdetail',
      //   name: 'shopDetail',
      //   component: () => import('../pages/shopDetail/index')
      // },
      {
        path: '/shopcar',
        name: 'shopCar',
        component: () => import('../pages/shopCar/index')
      },
      {
        path: '/mine',
        name: 'mine',
        redirect: '/mine/index',
        component: () => import('../pages/mine/index'),
        children: [
          {
            path: 'index',
            name: 'mineIndex',
            component: () => import('../pages/mine/mineIndex/index')
          },
          {
            path: 'adress',
            name: 'mineAdress',
            component: () => import('../pages/mine/mineAdress/index')
          },
          {
            path: 'order',
            name: 'mineOrder',
            component: () => import('../pages/mine/mineOrder/index')
          },
          {
            path: 'contract',
            name: 'mineContract',
            component: () => import('../pages/mine/mineContract/index')
          },
          {
            path: 'basicinf',
            name: 'mineBasicInf',
            component: () => import('../pages/mine/mineBasicInf/index')
          }
        ]
      }
    ]
  }, // 要加在这后面就行，按照这三行的格式。有其他写法，不过个人认为这种最规范
  {
    path: '/home',
    name: 'home',
    component: () => import('../pages/nylonPage/indexPage.vue'),
    children: [
      {
        path: 'homePage',
        name: 'homePage',
        meta: {
          title: '中国尼龙交易网首页'
        },
        component: () => import('../pages/nylonPage/homePage.vue')
      },
      {
        path: 'enterprisePage',
        name: 'enterprisePage',
        meta: {
          title: '企业展示'
        },
        component: () => import('../pages/nylonPage/enterprisePage.vue')
      },
      {
        path: 'enterpriseDetail',
        name: 'enterpriseDetail',
        component: () => import('../pages/nylonPage/enterpriseDetail.vue')
      },
      {
        path: 'presalePage',
        name: 'presalePage',
        meta: {
          title: '金融支持'
        },
        component: () => import('../pages/nylonPage/presalePage.vue')
      },
      {
        path: 'productPage',
        name: 'productPage',
        meta: {
          title: '产品展示'
        },
        component: () => import('../pages/nylonPage/productPage.vue')
      },
      {
        path: 'pricePage',
        name: 'pricePage',
        meta: {
          title: '数据大厅'
        },
        component: () => import('../pages/nylonPage/pricePage.vue')
      },
      {
        path: 'tradePage',
        name: 'tradePage',
        meta: {
          title: '交易大厅'
        },
        component: () => import('../pages/nylonPage/tradePage.vue')
      },
      {
        path: 'supplyChainPage',
        name: 'supplyChainPage',
        meta: {
          title: '供应链'
        },
        component: () => import('../pages/nylonPage/supplyChainPage.vue')
      },
      {
        path: 'productDetale',
        name: 'productDetale',
        component: () => import('../pages/nylonPage/components/productDetail.vue')
      },
      {
        path: 'tradeDetail',
        name: 'tradeDetail',
        component: () => import('../pages/nylonPage/components/tradeDetail.vue')
      },
      {
        path: 'priceQuotes',
        name: 'priceQuotes',
        meta: {
          title: '价格行情'
        },
        component: () => import('../pages/nylonPage/priceQuotes.vue')
      },
      {
        path: 'DetailpriceQuotes',
        name: 'DetailpriceQuotes',
        meta: {
          title: '数据交易大厅详情'
        },
        component: () => import('../pages/nylonPage/components/Detail-priceQuotes.vue')
      }
    ]
  }, {
    path: '/nylogin',
    name: 'nylogin',
    component: () => import('../pages/nylonPage/loginPage.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/login/login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../pages/login/register')
  },
  {
    path: '/bigScreen',
    name: 'bigScreen',
    component: () => import('../pages/nylonPage/bigScreen.vue')
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('../pages/login/forget')
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('../pages/authentication/index')
  }

]

const router = new VueRouter({
  // base: process.env.BASE_URL,
  routes
})

export default router
